import { useEffect, useRef } from 'react'
export default function Banner({height = 50, width=320}){
    const banner = useRef()
    const atOptions = {
        key: 'ece83bbb8403553e4c244bc63e4d829b',
        format: 'iframe',
        height: height,
        width: width,
        params: {},
    }
    useEffect(() => {
    if (banner.current && !banner.current.firstChild) {
        const conf = document.createElement('script')
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
        banner.current.append(conf)
        banner.current.append(script)
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [banner])

    return <div className="bg-gray-900 flex items-center justify-center" ref={banner}></div>
}
