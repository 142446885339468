/* eslint-disable jsx-a11y/iframe-has-title */
// responsive layout for small medium and lg screens

import { download, encrypt } from "../Utilities/common"
import ADBanner from "./AdBanner"
import Timer from "./Timer"
// taken from https://tailwindflex.com/@kofi-osei/responsive-grid-layout
const CreateQuiz = () => {

    // download file
    const downloadFile = () =>{
        const quizContent = encrypt(JSON.parse(document.getElementById('quizContent').value))
        download('sample.json',quizContent)
    }


    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {/* mobile header banner */}
           <ADBanner height={60} width={468}/>
           <Timer/>
            <div className="bg-gray-900 body-font md:h-lvh md:col-span-3">
                <div className="mx-10 py-10">
                    <p className="text-yellow-400 mb-3 text-2xl text">Paste your JSON Quiz content below</p>
                    <textarea id="quizContent" className="text-black-900 p-2 w-full" style={{"height":'350px'}}/> <br/>
                  
                    <button className='bg-blue-900 text-white px-10 py-5 rounded float-right' onClick={downloadFile}> Download </button>
                    <button className='bg-blue-900 text-white px-10 py-5 rounded float-left'> Reset </button>
                </div>
            </div>
           
            {/* <div className="bg-gray-300 p-4 md:col-span-1">
               
            </div> */}
        </div>
    )
}

export default CreateQuiz
